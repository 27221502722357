@import "common.css";
@import "setup.css";

.vo-search-html {
	min-height: 100%;
	height: 100%;
}

.list-view {
	.map-col {}
}

.map-pane {

	&.layout-column {
		@media (min-width:993px) {
			height: 580px;
		}

		@media (max-width:992px) {
			height: 520px;
			margin-top: 15px;
		}

		@media (max-width:420px) {
			height: 560px;
		}


		.no-map {
			.mapconsent {
				@media (min-width:993px) {
					height: 540px;
				}

				@media (max-width:992px) {
					height: 530px;
				}

				@media (max-width:420px) {
					height: 570px;
				}
			}


		}
	}



	.mapnote {
		padding: 0 0 20px;

		@media (max-width:992px) {
			padding: 15px;
		}

	}

	.map-main {
		>.flex {
			width: 100%;
			max-height: 510px;
			height: 100vh;

			@media (max-width:992px) {
				height: calc(100vh - 155px);
			}

			>div {
				height: 100%;
			}
		}
	}


	.no-map {


		>.flex {
			width: 100%;
			max-height: 767px;
			height: 100vh;

			>div {
				height: 100%;

				.mapconsent {

					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					background: rgba(26, 121, 187, 0.15);
					flex-direction: column;
					padding: 0 15px;


					.mapconsent-button {
						background: var(--primary);
						color: #fff;
						border-radius: 5px;
						padding: 0 15px 0 15px;
						height: 36px;
						display: block;
						line-height: 35px;

						&:hover {
							background: var(--secondary);
						}
					}
				}
			}
		}

	}

}

.map-view {
	.map-col {

		.affix,
		.affix-bottom {
			@media (min-width:993px) {
				position: relative !important;
				top: 0 !important;
				width: 100%;
				padding-right: 0;
				max-width: inherit;
				margin-top: 375px;
			}
		}
	}
}


.map-col {
	.affix {
		@media (max-width:992px) {
			position: relative !important;
		}

		@media (min-width:993px) {
			width: 33.33%;
			max-width: inherit;
			padding-right: 30px;
			top: 60px;
		}

		@media (min-width:1440px) {
			position: fixed !important;
			top: 50px;
			width: 100%;
			max-width: 450px;
		}

	}

	.affix-bottom {
		@media (max-width:992px) {
			position: relative;
			top: inherit !important;
		}
	}
}

.unit-row {
	margin-bottom: 15px;

	.offer-box {
		padding: 10px;
		position: relative;
		display: flex;
		margin: 0;
		align-items: center;
		flex-wrap: wrap;
		background-color: var(--white);
		color: var(--black);
		border: 1px solid #ebebeb;

		@media (max-width:767px) {
			display: block
		}

		.offer-left {
			color: var(--secondary);
			font-size: 14px;
			text-transform: uppercase;
			display: flex;
			margin-right: 10px;
			align-items: center;

			@media (max-width:767px) {
				display: block;
				text-align: center;
				margin-right: 0;
				margin-bottom: 15px
			}
		}

		ul {
			padding: 0;
			list-style: none;
			margin: 0;

			li {
				margin: 0 0 5px;
				line-height: 14px;
				font-size: 14px
			}
		}
	}
}

.search-rt-head {
	@media (min-width:768px) {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	@media (max-width:767px) {
		margin-bottom: 15px;
	}

	.sorting-item {
		background-color: var(--secondary);
		padding: 5px 10px;
		color: var(--white) !important;

		&:hover {
			background-color: var(--primary);
			text-decoration: none;
		}
	}

	.icon-grid {
		&:before {
			content: "\f00a";
			font-family: fontawesome;
			margin-right: 5px;
		}
	}

	.icon-ascend {
		&:before {
			content: "\f15d";
			font-family: fontawesome;
			margin-right: 5px;
		}
	}

	.icon-tag {
		&:before {
			content: "\f02b";
			font-family: fontawesome;
			margin-right: 5px;
		}
	}
}

.vo-search-body {
	min-height: 100%;
	height: 100%;
	@mixin flexlayout column;

	.result-pane {
		width: 100%;

		@media (min-width:993px) {
			padding: 0 15px;
		}

		color: #565a5c;

		a {
			color: inherit;
		}

		.a-color {
			color: #007da7;
		}

		.result-header {
			padding: 0 5px 0;
			border-bottom: 1px solid rgb(235, 235, 235);
			margin-bottom: 15px;


			.sorting-trigger {
				cursor: pointer;
			}

			.mysearch {
				margin-bottom: 4px;
			}
		}

		.pagination-panel {
			text-align: center;
		}

	}

	.map-view {
		.result-pane {
			@media (min-width:993px) {
				display: none;
			}
		}

		.list-content {
			@media (min-width:993px) {
				display: none;
			}
		}

		.map-col {
			width: 100%;
		}
	}

	.show-map-list {
		position: absolute;
		margin-top: -35px;
	}

	.map-pane {
		/* 		
     background-color: #f5f5f5;
     */
		padding: 25px 0;

		.header {
			display: none;
			padding: 5px;
			background-color: var(--white);

			.close {
				font-size: 46px;
				font-weight: normal;
			}
		}
	}

	.map-unit-preview {
		color: #565a5c;
		text-align: center;

		a {
			color: inherit;
		}

		.prices {
			font-size: 14px;
			margin-top: 6px;
			font-weight: 400;
		}

		.preview-img {
			min-height: 150px;

			img {
				max-width: 225px;
			}
		}

		.lazy-preview-placeholder {
			height: 350px;
			width: 240px;
		}

	}



	.more-btn-panel {
		padding-bottom: 40px;
	}

	.spinner-panel {
		padding-bottom: 20px;
	}

	.results {

		padding: 0;

		.no-search-results {
			margin-top: 20px;
			margin-bottom: 27px;
			width: 100%;
			text-align: center;
		}

		.altheading {
			color: red;
		}

		h4 {
			margin-top: 0;
		}

		.srow {
			position: relative;

			.map-marker {
				position: absolute;
				top: 0;
				right: 0;
				display: inline-block;
				width: 50px;
				height: 50px;
				text-align: center;

				.fa-map-marker-alt {
					position: relative;
					top: 12px;
					cursor: pointer;
					font-size: 24px;
					color: #dedede;
				}

				.fa-map-marker-alt:hover,
				.fa-map-marker-alt.selected {
					color: #565a5c;
				}
			}

			.img-col {

				.img-content {

					.full-img {
						width: 100%;
						height: auto;

					}


					.fav-icon {
						position: absolute;
						cursor: pointer;
						right: 0;
						top: 0;
						color: var(--primarycolor);
						margin: 3px;
						font-size: 24px;
						width: 35px;
						text-align: center;
					}


				}


			}

			.info-col {
				position: relative;
				padding: 3px 3px 3px 6px;
				background-color: white;

				border-radius: 5px;
				box-shadow: 0 1px 3px rgba(0, 0, 0, .1);

				.stars {
					position: absolute;
					right: 0;
					bottom: 0;
				}

				.prices {
					.price {
						font-size: 20px;
					}
				}

				.search-result-properties {
					margin-top: 5px;
					margin-bottom: 15px;
				}


			}
		}


	}


	.search-result-properties {
		display: flex;
		text-align: center;
		flex-wrap: wrap;
		justify-content: center;
		min-height: 90px;
		align-items: center;

		.ft-icon {
			text-align: center;
			display: inline-flex;
			align-items: center;
			color: #fff;
			border: 1px solid #fff;
			padding: 5px 10px;
			margin: 5px;
			background-color: var(--secondary);

			.number {
				margin-left: 5px;
			}
		}

	}



	.search-bar {
		padding: 150px 0 30px 0;

		&.search-banner {
			background-image: url(RESOURCE/img/steine.jpg);
			width: 100%;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center top;

			.form-control {
				margin-bottom: 5px;
			}
		}

		.adv-filt-form {
			background: rgba(255, 255, 255, 0.9);
			padding: 15px;

			.panel-title {
				font-weight: 700;
			}
		}



		.btn {
			margin: 2px;
		}

		@media (max-width:768px) {
			.sm-full {
				display: block;

			}
		}
	}

	@media (max-width:992px) {

		.list-view {
			.map-pane {
				display: none;
			}

			.result-pane {
				display: block;
			}
		}

		.map-view {
			.map-pane {
				@mixin flex;
			}

			.result-pane {
				display: none;
			}
		}


		.map-pane.full-screen {
			display: flex;
			position: fixed !important;
			left: 0px !important;
			right: 0px !important;
			bottom: 0px !important;
			top: 0px !important;
			height: 100vh;

			.header {
				display: block;
			}

		}

		.pagination-panel {
			display: none;
		}

	}


	.results {
		.unit-box {
			border: 1px solid var(--white);
			position: relative;


			.rent-rate {
				position: absolute;
				top: 60px;
				z-index: 1;
				right: 0;
				background-color: var(--secondary);
				padding: 5px 10px;
				color: var(--white);
				font-weight: bold;
			}

			.unit-title {
				color: var(--white);
				padding: 10px;
				text-align: center;
				background-color: var(--primary);
				position: absolute;
				top: 0;
				width: 100%;

				font-size: 16px;
				z-index: 1;
			}

			.img-content {
				position: relative;

				.fav-icon {
					position: absolute;
					left: 5px;
					bottom: 5px;
					color: var(--white);
				}

				.map-marker {
					position: absolute;

					bottom: 0;
					top: inherit;
					right: 0;

					i {
						color: var(--white);
					}
				}

				img {
					width: 100%;
				}
			}

			.unit-cont-bl {
				background-color: var(--white);
				padding: 10px 15px;
				height: 60px;
				overflow: hidden;
				margin-bottom: 10px;
			}

			.unit-feat {
				background-color: var(--grey);

				.ft-icon {
					background-color: var(--secondary);
				}


			}
		}
	}


	.map-pane {
		@mixin flex;


		@media (max-width:992px) {
			z-index: 50;
			padding: 0;
		}
	}

	@media (min-width:768px) {


		.result-pane {
			.more-btn-panel {
				display: none;
			}


		}






	}





}