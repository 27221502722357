.main-footer {
	background-color: var(--primary);
	color: var(--white);
	font-size: 14px;
	padding: 15px;

	.social-media {
		margin-bottom: 20px;

		@media (min-width:993px) {
			width: 90px;
		}

		@media (min-width:993px) {
			padding: 0px 0 0 10px;
		}

	}

	.copyright {
		padding: 15px 0 0;
		margin-top: 15px;
	}

	h4 {
		margin-top: 0;
		text-transform: uppercase;
		font-size: 14px;
		width: 100%;

		@media (max-width:992px) {
			marign-top: 15px;
		}
	}

	a {
		color: #fff;
		font-weight: 400;
	}

	ul {
		li {
			list-style-type: none;
			line-height: 20px;
		}
	}

	.partner {
		float: left;
	}

	.footer-logoes {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		@media (max-width: 767px) {
			margin-top: 20px;
		}

		.partner-logoes {
			max-width: 300px;
			width: 100%;

			.partner {
				margin: 0 15px 15px 0;
				text-align: center;
				padding: 0;

				img {
					width: auto;
					height: 65px;
					border: 1px solid;
					padding: 10px;
					border-radius: 10px;
				}
			}
		}
	}

}