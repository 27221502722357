@import "font-awesome-custom.css";
@import "font-awesome-v5.css";

@define-mixin flexlayout $direction {

	/* OLD - iOS 6-, Safari 3.1-6, BB7 */
	display: -webkit-box;
	/* TWEENER - IE 10 */
	display: -ms-flexbox;
	/* NEW - Safari 6.1+. iOS 7.1+, BB10 */
	display: -webkit-flex;
	/* NEW, Spec - Firefox, Chrome, Opera */
	display: flex;

	-webkit-flex-direction: $(direction);
	-ms-flex-direction: $(direction);
	flex-direction: $(direction);
}

.layout-column {
	@mixin flexlayout column;
}

.layout-row {
	@mixin flexlayout row;
}


@define-mixin flex {
	/* OLD - iOS 6-, Safari 3.1-6 */
	-webkit-box-flex: 1;
	/* Safari 6.1+. iOS 7.1+, BB10 */
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1;
	flex: 1 1 0;

	min-width: 0;
	min-height: 0;
}

.doc-box {
	text-align: left;
}

.flex {
	@mixin flex;
}


@define-mixin columnlayout $rows,
$gap: 1em {
	-webkit-column-count: $(rows);
	/* Chrome, Safari, Opera */
	-moz-column-count: $(rows);
	/* Firefox */
	column-count: $(rows);

	-webkit-column-gap: $(gap);
	/* Chrome, Safari, Opera */
	-moz-column-gap: $(gap);
	/* Firefox */
	column-gap: $(gap);
}


@define-mixin size100 {
	width: 100%;
	height: 100%;
}

.size100 {
	@mixin size100;
}

@define-mixin overflowscrolls {
	display: block;
	overflow: auto;
	position: relative;
	-webkit-overflow-scrolling: touch;
}

.overflowscrolls {
	@mixin overflowscrolls;
}


.text-truncate {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
}

.hidden {
	display: none;
}

.btn-go {
	background-color: #007da7;
	color: #FFF !important;
	border: 1px solid #026B8E;
	//box-shadow: 0 1px 1px #eee;
}


.btn-go:hover,
.btn-go:focus,
.btn-go:active,
.btn-go.active {
	color: #FFF;
	background-color: #0187B4;
	border: 1px solid #09BDF3;
}


.clean-form {
	select {
		-moz-appearance: none;
		-webkit-appearance: none;
	}
}

.grey-bg {
	border-top: 1px solid #eaebec;
	border-bottom: 1px solid #eaebec;
	background: #F7F8FA;
}

.glyph {
	width: 26px;
	height: 26px;
	display: inline-block;
}

.glyph.i-door {
	background: no-repeat url(RESOURCE/img/door.png) 0 0;
}

.confirmation {
	.fa-check {
		color: green;
	}
}

.list-striped li:nth-of-type(odd) {
	background-color: #f9f9f9;
}

.list-striped-dark li:nth-of-type(odd) {
	background-color: #dadada;
}

.pano {
	min-height: 400px;
	margin-bottom: 100px !important;
}


.slider.slider-horizontal {
	width: 100%;
}


.contact-form {
	label {
		font-weight: normal;
	}

	label.lrequired {
		font-weight: 700;
	}

	margin-bottom: 20px;
}

.invalid {
	text-decoration: line-through;
}

.special {
	color: red;
}

.asd__day--tada {
	background-color: red;
}

.datepicker-trigger {
	--cal_not_available: #cd7898;

	.asd__day--not-available {
		background: var(--cal_not_available) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival {
		background: linear-gradient(90deg, white 70%, var(--cal_not_available) 80%) !important;
		opacity: 1 !important;
	}

	.asd__day--departure {
		background: linear-gradient(90deg, var(--cal_not_available) 20%, white 30%) !important;
		opacity: 1 !important;
	}

	.asd__day--arrival:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, #e4e7e7 70%, var(--cal_not_available) 80%) !important;
	}

	.asd__day--departure:hover:not(.asd__day--disabled):not(.asd__day--selected) {
		background: linear-gradient(90deg, var(--cal_not_available) 20%, #e4e7e7 30%) !important;
	}

	.asd__day--disabled.asd__day--in-range {
		background-color: #ebf4f8 !important;
		color: #000 !important;
		border-color: transparent !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--hovered {
		background: #eee !important;
		opacity: 1 !important;
	}

	.asd__day--disabled.asd__day--arrival:not(.asd__day--selected),
	.asd__day--disabled.asd__day--departure:not(.asd__day--selected) {
		color: rgba(86, 90, 92, 0.5) !important;
	}

	.asd__day--in-range {
		background-color: #ebf4f8 !important;
		color: #000 !important;
		border-color: transparent !important;
	}

	.asd__day--selected {
		background-color: #357ebd !important;
		color: #fff !important;
		opacity: 1 !important;
	}

	.asd__month-name {
		font-size: 1em;
	}

	.asd__day--enabled {
		border: 1px solid transparent !important;
	}

	.asd__day--hovered {
		background-color: #ebf4f8 !important;
		color: #000 !important;
		border-color: transparent !important;
	}

	.asd__selected-date-one {
		border-radius: 4px 0 0 4px;
	}

	.asd__selected-date-two {
		border-radius: 0 4px 4px 0;
	}



}



/*
.pswp {
	display: none;
}
*/

.app-loaded #preloader {
	display: none;
}

.app-leaving #preloader {
	display: block;
	opacity: 0.6;
}

body {
	background-color: var(--grey);
	font-family: var(--fontfamily1);
	font-weight: 400;
	color: var(--dark-grey);
	font-size: 15px;
}

.wrapper {
	max-width: 1170px;
	width: 100%;
	margin: 0 auto;
	position: relative;
	background-color: var(--white);
}

.min-row {
	margin-left: -7px;
	margin-right: -7px;

	.col-xs-6,
	.col-md-12 {
		padding-left: 7px;
		padding-right: 7px;
	}
}

.hide-mob {
	@media (max-width:992px) {
		display: none;
	}
}

.container-fluid {
	width: 100%;
}

button,
.btn,
input,
.form-control,
a {
	outline: 0;
}

ul {
	padding: 0;
}

.heading-blue {
	font-size: 15px;
	font-weight: 400;
	color: #fff;
	text-transform: uppercase;
	background-color: var(--primary);
	padding: 10px 10px 8px;
	margin: 20px 0;

}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: 1.1;
}

p {
	line-height: 24px;
}

.heading5 {
	margin-top: 0;
	font-size: 15px;
	font-weight: bold;
}

.form-control {
	background: #fff !important;
	border-radius: 0;
}

.btn {
	border-radius: 0;

	&.btn-secondary {
		background-color: var(--secondary);
		text-align: center;
		color: var(--white) !important;
		border-color: var(--secondary);
	}

	&.btn-warning {
		background-color: var(--secondary);
		text-align: center;
		color: var(--white) !important;
		border-color: var(--secondary);
	}

	&.btn-primary {
		background-color: var(--primary);
		text-align: center;
		color: var(--white) !important;
		border-color: var(--secondary);
	}
}

.form-horizontal .control-label {
	text-align: left;
	font-weight: 700;
	padding-bottom: 5px;
}


.h2,
h2 {
	font-size: 24px;
}

.h1,
h1 {
	font-size: 28px;
}

.asd__wrapper--full-screen {
	z-index: 5;
}

.f-secondary {
	font-family: var(--fontfamily2);
}

.bg-partner {
	height: 263px;
	background-color: #CFD0D0;
	text-align: center;
	padding: 0px 0 10px 0;

	figure {
		display: inline-block;
	}
}

.contact-form {
	label {
		a {
			position: relative;
			top: 1px;
		}
	}

	.btn {
		margin-top: 15px;
	}
}

.not-found {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 300px;
}

.text-right {
	text-align: right;
}

.mt-30 {
	margin-top: 30px !important;
}

.mb-30 {
	margin-bottom: 30px;
}

.two-cols {
	.col-md-6 {
		&:nth-child(2n+1) {
			clear: both;
		}
	}
}

.doc-box {
	color: black;

}

.guestNotice {
	display: block;
	margin: -10px 0 0 0;
	color: #000;
	font-weight: 400;
	line-height: normal;
}

.bspfoto::after {
	content: "Beispielfotos";
	font-size: 0.8em;
	color: grey;
	vertical-align: super;
	margin-left: 8px;
}

@font-face {
	font-family: 'PT Sans';
	src: url('RESOURCE/fonts/PTSans-Regular.eot');
	src: url('RESOURCE/fonts/PTSans-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/PTSans-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/PTSans-Regular.woff') format('woff'),
		url('RESOURCE/fonts/PTSans-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/PTSans-Regular.svg#PTSans-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'PT Sans';
	src: url('RESOURCE/fonts/PTSans-Bold.eot');
	src: url('RESOURCE/fonts/PTSans-Bold.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/PTSans-Bold.woff2') format('woff2'),
		url('RESOURCE/fonts/PTSans-Bold.woff') format('woff'),
		url('RESOURCE/fonts/PTSans-Bold.ttf') format('truetype'),
		url('RESOURCE/fonts/PTSans-Bold.svg#PTSans-Bold') format('svg');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Handlee';
	src: url('RESOURCE/fonts/Handlee-Regular.eot');
	src: url('RESOURCE/fonts/Handlee-Regular.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/Handlee-Regular.woff2') format('woff2'),
		url('RESOURCE/fonts/Handlee-Regular.woff') format('woff'),
		url('RESOURCE/fonts/Handlee-Regular.ttf') format('truetype'),
		url('RESOURCE/fonts/Handlee-Regular.svg#Handlee-Regular') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Indie Flower';
	src: url('RESOURCE/fonts/IndieFlower.eot');
	src: url('RESOURCE/fonts/IndieFlower.eot?#iefix') format('embedded-opentype'),
		url('RESOURCE/fonts/IndieFlower.woff2') format('woff2'),
		url('RESOURCE/fonts/IndieFlower.woff') format('woff'),
		url('RESOURCE/fonts/IndieFlower.ttf') format('truetype'),
		url('IndieFlower.svg#IndieFlower') format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'FontAwesome';
	src: url('RESOURCE/fonts/fontawesome-webfont.eot?v=4.7.0');
	src: url('RESOURCE/fonts/fontawesome-webfont.eot?#iefix&v=4.7.0') format('embedded-opentype'),
		url('RESOURCE/fonts/fontawesome-webfont.woff2?v=4.7.0') format('woff2'),
		url('RESOURCE/fonts/fontawesome-webfont.woff?v=4.7.0') format('woff'),
		url('RESOURCE/fonts/fontawesome-webfont.ttf?v=4.7.0') format('truetype'),
		url('RESOURCE/fonts/fontawesome-webfont.svg?v=4.7.0#fontawesomeregular') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Font Awesome 5 Free';
	font-style: normal;
	font-weight: 900;
	font-display: block;
	src: url("RESOURCE/fonts/fa-solid-900.eot");
	src: url("RESOURCE/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"),
		url("RESOURCE/fonts/fa-solid-900.woff2") format("woff2"),
		url("RESOURCE/fonts/fa-solid-900.woff") format("woff"),
		url("RESOURCE/fonts/fa-solid-900.ttf") format("truetype"),
		url("RESOURCE/fonts/fa-solid-900.svg#fontawesome") format("svg");
}

.fas {
	font-family: 'Font Awesome 5 Free';
	font-weight: 900;
	font-style: normal;
}