.inner-page {
	background-color: var(--white);
	padding-bottom: 15px;

	ul.tick-li {
		li {
			list-style: none;
			padding-left: 30px;
			position: relative;

			&:before {
				font-family: 'Font Awesome 5 Free';
				content: "\f00c";
				font-size: inherit;
				position: absolute;
				left: 5px;
				top: -2px;
			}
		}
	}
}

.contact-form .form-horizontal .form-group {
	margin: 0 0 15px;
}

.no-pad {
	padding-left: 35px;
}