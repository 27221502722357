@import "setup.css";

.indexpage {
	.header-first {
		position: absolute;
		width: 100%;
		z-index: 5;
		padding: 20px;
		background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.7) 51%, rgba(255, 255, 255, 0) 100%);
		background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.7) 51%, rgba(255, 255, 255, 0) 100%);
		background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.7) 51%, rgba(255, 255, 255, 0) 100%);
		justify-content: space-between;

		@media (min-width:993px) {
			display: flex;
			align-items: center;
			padding: 30px;
		}

		.logo {
			max-width: 300px;
			display: inline-block;
		}

		.top-right {
			h2 {
				font-size: 22px;
				color: var(--primary);
				font-weight: bold;
				margin: 0;
				font-family: sans-serif;
			}

			.fa-phone {
				transform: rotate(90deg) !important;
				display: inline-block;
				font-size: 16px;
			}

			ul {
				padding: 0;
				margin: 0;

				li {
					display: inline-block;
					font-size: 19px;
					margin-right: 12px;
					font-family: sans-serif;

					.fa {
						margin-right: 3px;
					}


				}
			}
		}
	}

	.banner-slide {
		position: relative;
		background-color: #fff;
		padding-bottom: 8px;

		.item {
			background-size: cover;
			height: 530px;
			width: 100%;
			max-width: 1170px;
			background-repeat: no-repeat;
			background-position: center;

			&.slide1 {
				background-image: url(RESOURCE/img/strandkorb.jpg);
			}

			&.slide2 {
				background-image: url(RESOURCE/img/steine.jpg);
			}

			&.slide3 {
				background-image: url(RESOURCE/img/herz.jpg);
			}
		}

		.owl-dots {
			position: absolute;
			width: 100%;
			z-index: 2;
			bottom: 10px;
			text-align: center;
			left: 0;

			.owl-dot {
				font-size: 0;
				line-height: 0;
				display: inline-block;
				width: 16px;
				height: 16px;
				padding: 5px;
				cursor: pointer;
				margin: 0 5px;
				border: 0;
				outline: none;
				background: #000;
				border-radius: 50%;
				opacity: .25;

				&:hover {
					opacity: 1;
				}

				&.active {
					opacity: .75;
					background: var(--white);
				}
			}
		}
	}

	.owl-nav {
		position: absolute;
		top: 45%;
		padding: 0 30px;
		width: 100%;

		button {
			span {
				font-size: 60px !important;
				line-height: 1;
				opacity: .75;
				color: var(--white) !important;
			}


			&.owl-next {
				right: 30px;
				position: absolute;

				@media (max-width:767px) {
					right: 0px;
				}
			}

			&.owl-prev {
				left: 30px;
				position: absolute;

				@media (max-width:767px) {
					left: 0px;
				}
			}
		}
	}

	.subscribe-box {
		.form-control {
			margin: 10px 0 10px 0;
		}
	}

	.iheader {

		min-height: 500px;

		@media (min-width:1200px) {
			min-height: 700px;
		}

		background: var(--introImg) no-repeat center;
		-webkit-background-size: cover;
		background-size: cover;

		position: relative;


		.intro {
			text-align: center;

			.hero {
				text-align: center;
				color: #fff;
				background: none;

				margin-bottom: 10px;
				padding-bottom: 0px;

				h1,
				h2 {
					text-shadow: -3px 0 3px rgba(0, 0, 0, .6), 0 3px 3px rgba(0, 0, 0, .6), 3px 0 3px rgba(0, 0, 0, .6), 0 -3px 3px rgba(0, 0, 0, .6);
				}

				h1 {

					font-family: 'Raleway', sans-serif;
					font-weight: 700;
					margin: 60px 0 0;
					text-shadow: 0 0 10px #000;

				}

				h2 {
					font-size: 24px;

					font-family: 'Raleway', sans-serif;
					font-weight: 700;
					margin: 20px 0 0;
					text-shadow: 0 0 10px #000;

				}

			}

		}


	}



	.booking-home {
		position: absolute;
		bottom: 50px;
		right: 60px;
		width: 375px;
		background-color: rgba(0, 127, 165, 0.9);
		-webkit-border-radius: 30px;
		-moz-border-radius: 30px;
		border-radius: 30px;
		text-align: center;
		z-index: 5;

		.btn {
			margin-top: 5px;
		}

		@media (max-width:992px) {
			right: 5%;
			width: 315px;
		}

		@media (max-width:350px) {
			width: 285px;
		}

		.handle {
			background-image: url(RESOURCE/img/koffer-henkel-new.png);
			background-position: center;
			background-repeat: no-repeat;
			height: 48px;
			width: 100%;
			position: absolute;
			top: -35px;
			left: 0;
			right: 0;
			text-align: center;

			&:after {
				background-color: rgba(0, 127, 165, 0.9);
				content: "";
				height: 15px;
				width: 100%;
			}
		}

		.searchbar {
			-webkit-border-radius: 30px;
			-moz-border-radius: 30px;
			border-radius: 30px;
			padding: 25px 15px 15px 15px;
			border: 1px solid var(--white);
			margin: 8px;

			h2 {
				color: var(--white);
				text-transform: uppercase;
				font-size: 29px;
				font-family: var(--fontfamily1);
				padding: 0 16px;
				margin: 0;
				line-height: 1;
				font-weight: 700;

				@media (max-width:992px) {
					font-size: 24px;
				}
			}
		}

		.searchbox {
			padding: 5px 0;
			text-align: left;

			label {
				color: var(--white);
				font-family: var(--fontfamily1);
			}

			.search-btn {
				position: relative;

				&:before {
					content: "\f002";
					font-family: fontawesome;
					margin-right: 5px;

				}
			}

			input,
			.search-btn,
			select {
				border-radius: 0;
			}

			.search-btn {
				width: 100%;
			}

			.search-popup-trigger {
				cursor: pointer;
			}

			.form-control:disabled,
			.form-control[readonly] {
				background-color: white;
			}
		}

	}

	.contact-section {
		background-color: #fff !important;
	}






	@media (max-width:767px) {

		.iheader {
			background: var(--introMobileImg) no-repeat center;
			-webkit-background-size: cover;
			background-size: cover;

			.intro {
				.hero {
					h2 {
						font-size: 18px;
					}
				}
			}


		}
	}






}