.booking-view {

	.cart-details {
		h4 {
			font-size: 15px;
			font-weight: 400;
			color: #fff;
			text-transform: uppercase;
			background-color: var(--primary);
			padding: 10px 10px 8px;
			margin: 20px 0 10px;
		}

		.unit-details {
			margin-top: 10px;
			padding-left: 0;

			li {
				margin-left: 0px;
				list-style: none;

				&:before {
					content: "\f00c";
					color: var(--primary);
					font-family: fontawesome;
					margin-right: 5px;
				}
			}
		}
	}

	.pd-form {
		label {
			font-weight: 700;
		}

		label.lrequired {
			font-weight: 700;
		}

	}

	.final-col {
		.checks {
			margin-bottom: 30px;

			label {
				font-weight: 700;
			}
		}

		margin-top: 10px;
		margin-bottom: 20px;
	}

	.flow-btn {
		margin-left: 20px;
		margin-bottom: 20px;
	}


}