:root {
	--introImg: url(RESOURCE/img/banner.jpg);
	--introMobileImg: url(RESOURCE/img/banner.Xc_770_770.jpg);
	--navbarbreak: 768px;
	--primary: rgb(0, 125, 164);
	--white: rgb(255, 255, 255);
	--grey: #efefef;
	--dark-grey: #6f6f6f;
	--fontfamily1: 'PT Sans', sans-serif;
	--fontfamily2: 'Handlee', cursive;
	--secondary: #48b3e2;
}