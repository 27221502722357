@import "setup.css";

.vo-nav-bar {
	background-color: var(--primary);



	.header-first {
		position: relative;
		width: 100%;
		padding: 20px;
		justify-content: space-between;
		background-image: url(RESOURCE/img/strandkorb.jpg);
		display: none !important;

		&:after {
			background: -moz-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.7) 51%, rgba(255, 255, 255, 0) 100%);
			background: -webkit-linear-gradient(top, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.7) 51%, rgba(255, 255, 255, 0) 100%);
			background: linear-gradient(to bottom, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0.7) 51%, rgba(255, 255, 255, 0) 100%);
			content: "";
			width: 100%;
			height: 100%;
			position: absolute;
			left: 0;
			top: 0;
		}

		@media (min-width:993px) {
			display: flex;
			align-items: center;
			padding: 30px;
		}

		.logo {
			max-width: 300px;
			display: inline-block;
			position: relative;
			z-index: 2;
		}

		.top-right {
			position: relative;
			z-index: 2;

			.fa-phone {
				transform: rotate(90deg);
				font-size: 16px;
				display: inline-block;
			}

			h2 {
				font-size: 22px;
				color: var(--primary);
				font-weight: bold;
				margin: 0;
				font-family: sans-serif;

			}

			ul {
				padding: 0;
				margin: 0;

				li {
					display: inline-block;
					font-size: 19px;
					margin-right: 12px;
					font-family: sans-serif;

					a {
						color: var(--primary);
					}

					.fa {
						margin-right: 3px;
					}


				}
			}
		}
	}

	&.affix {
		top: 0;
		z-index: 5;
		max-width: 1170px;
		width: 100%;

		.header-first {
			@media (min-width: 993px) {
				display: flex !important;
			}

			.top-right {
				.fa-phone {
					transform: rotate(90deg);
					font-size: 16px;
				}

				ul {
					li {
						font-family: sans-serif;
					}
				}
			}
		}

	}

	.navbar {
		margin-bottom: 0;

		ul {
			padding: 0;
			margin: 0;

			@media (max-width:992px) {
				display: none;
				background-color: var(--primary);
				width: 100%;
				z-index: 5;
			}

			>li {
				@media (max-width:992px) {
					display: block;
				}

				display: inline-block;

				>a {
					float: left;
					display: block;
					color: var(--white);
					text-align: center;
					text-decoration: none;
					font-size: 16px;
					text-transform: uppercase;
					padding: 10px 15px 10px;
					font-family: var(--fontfamily1);
					font-weight: 400;

					@media (max-width:992px) {
						float: none;
						text-align: left;
					}
				}
			}
		}


	}

	.icon {
		display: none;

		@media (max-width:992px) {
			display: block;
			position: absolute;
			top: 10px;
			color: #fff;
			z-index: 6;
			right: 15px;
		}
	}

	.vo-dropdown,
	.input-group {
		overflow: hidden;
	}

	.input-group {
		padding: 9px 16px;

		.form-control {}
	}

	.vo-dropdown .dropbtn {
		font-size: 16px;
		text-transform: uppercase;
		border: none;
		outline: none;
		color: var(--white);
		padding: 10px 15px 10px;
		background-color: inherit;
		font-family: inherit;
		margin: 0;
	}

	.vo-dropdown-content {
		display: none;
		position: absolute;
		background-color: #f9f9f9;
		min-width: 160px;
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
		z-index: 1;
	}

	.vo-dropdown-content a {
		display: block;
		padding: 3px 20px;
		clear: both;
		font-weight: 400;
		line-height: 1.42857143;
		white-space: nowrap;
		float: none;
		text-align: left;
		color: #333;
		background-color: #fff;
		text-transform: capitalize;
	}

	.vo-dropdown-content a:hover {
		color: #262626;
		background-color: #f5f5f5;

	}

	a:hover,
	.vo-dropdown:hover .dropbtn {
		background-color: transparent;
		color: white;
		text-decoration: none;
	}

	.vo-dropdown-content a:hover {
		background-color: #ddd;
		color: black;
	}


	.vo-dropdown:hover {
		color: red;
	}

	.vo-dropdown:hover .vo-dropdown-content {
		display: block;
	}

	.rightme {
		float: right;
		position: absolute;
		right: 0;
		top: 0;

		@media (max-width:992px) {
			display: none;
		}
	}


	@media screen and (max-width: var(--navbarbreak)) {

		a:not(:first-child),
		.input-group,
		.vo-dropdown .dropbtn {
			display: none;
		}

		a.icon {
			float: right;
			display: block;
		}
	}


	@media (min-width:var(--navbarbreak)) {
		.search {
			width: 150px;
			transition: width .3s ease-in-out;
			-webkit-transition: width .3s ease-in-out;
			-moz-transition: width .3s ease-in-out;
			-ms-transition: width .3s ease-in-out;
		}

		.search:focus-within {
			width: 250px;
		}



	}



}


#navbar {

	&.opened {
		.navbar {
			ul {
				left: 0;

				@media (max-width:992px) {
					display: block;
					position: absolute;
				}
			}
		}

	}
}

@media screen and (max-width: var(--navbarbreak)) {
	.vo-nav-bar.opened {
		position: relative;
	}


	.vo-nav-bar.opened a {
		float: none;
		display: block;
		text-align: left;
	}

	.vo-nav-bar.opened .input-group {
		display: table;
	}

	.vo-nav-bar.opened .vo-dropdown {
		float: none;
	}

	.vo-nav-bar.opened .vo-dropdown-content {
		position: relative;
	}

	.vo-nav-bar.opened .vo-dropdown .dropbtn {
		display: block;
		width: 100%;
		text-align: left;
	}
}

.default-ly {
	.vo-nav-bar {
		.header-first {
			@media (min-width:993px) {
				display: flex !important;
			}

			@media (max-width:992px) {
				display: block !important;
			}
		}
	}
}